import { useModalContext } from '@/hooks/useModalContext';
import { useEffect, useRef } from 'react';

export function ShowOnboardingModal() {
  const { openModal } = useModalContext();
  const showModalRef = useRef(false);

  useEffect(() => {
    if (showModalRef.current) return;
    openModal('mainOnboarding');
    showModalRef.current = true;
  }, [openModal]);

  return null;
}
