import { useTranslation } from '@/hooks/useTranslation';
import clsx from 'clsx';
import { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import { TooltipWrapper } from '../TooltipWrapper';

interface SidebarItemProps {
  icon: ReactNode;
  label: string;
  href: string;
  active: boolean;
  onClick?: () => void;
  className?: string;
}

export const SidebarItem = ({
  icon,
  label,
  href,
  active,
  className,
}: SidebarItemProps) => {
  const { t } = useTranslation('common');

  return (
    <TooltipWrapper label={t(label)} side="right">
      <NavLink
        id={`${label}-sidebar-item`}
        to={href}
        className={clsx(
          'flex h-9 w-9 items-center justify-center md:h-8 md:w-8 rounded-full',
          {
            'group shrink-0 gap-2 bg-primary text-lg font-semibold text-primary-foreground md:text-base':
              active,
            'hover:bg-accent text-accent-foreground transition-colors hover:text-foreground':
              !active,
          },
          className
        )}
      >
        {icon}
        <span className="sr-only">{t(label)}</span>
      </NavLink>
    </TooltipWrapper>
  );
};
