import { LanguageSelector } from './LanguageSelector';
import { ShowOnboardingModal } from './ShowOnboardingModal';
import { ColorSwitch } from './layout/ColorSwitch';
import { UserNav } from './UserNav';
import { SidebarMobile } from './layout/SidebarMobile';
import { Notifications } from './Notifications';
import { useNavigate } from 'react-router-dom';

export const Navbar = () => {
  const navigate = useNavigate();

  return (
    <header
      className="sticky top-0 flex h-16 items-center gap-4 border-b bg-background px-4 md:px-6 shadow-md z-20"
      id="navbar"
    >
      <nav className="hidden flex-col gap-6 text-lg font-medium md:flex md:flex-row md:items-center md:gap-5 md:text-sm lg:gap-6">
        <div
          className="flex items-center justify-center gap-2 cursor-pointer"
          onClick={() => {
            navigate('/dashboard');
          }}
        >
          <img
            src="/cc_vertical_128.png"
            alt="ConversionCraft"
            className="h-8"
          />
          <img
            src="/cc_vertical_title.png"
            alt="ConversionCraft"
            className="h-5"
          />
        </div>
      </nav>
      <SidebarMobile />
      <div className="flex w-full items-center justify-end gap-4 md:ml-auto md:gap-2 lg:gap-4">
        <Notifications />
        <LanguageSelector />
        <ColorSwitch />
        {/* <CurrencySelector /> */}
        <UserNav />
      </div>
      <ShowOnboardingModal />
    </header>
  );
};
