import { baseURL } from './hooks/useApi';
// import querystring from 'node:querystring';

import {
  AUTH,
  CAMPAIGN,
  CAMPAIGNS,
  EDITOR,
  END,
  IFRAME,
  LOGIN,
  ME,
  // EDITOR,
  POPUPS,
  PROFILE,
  RESTART,
  RESUME,
  STATS,
  SUSPEND,
  USER,
  USERS,
  WEBSITES,
} from './queryKeys';

export type QueryParamValueTypes = string | number | boolean | string[] | number[];

export type QueryParams = Record<string, QueryParamValueTypes> & {
  _limit?: number;
  _sort?: string;
  _order?: string;
  _from?: string;
  _to?: string;
};

export const parseQueryParams = (params?: QueryParams) => {
  if (!params) return '';

//   const query = querystring.stringify(params);

//   return query ? `?${query}` : '';
  
  const query = new URLSearchParams(params as Record<string, string>).toString();
  return query ? `?${query}` : '';
//   const query = Object.keys(params)
//     .map((key) => `${key}=${params[key]}`)
//     .join('&');
//   return query ? `?${query}` : '';
};

export const generateUrlWithParams = (
  postURL: string,
  params?: QueryParams
) => {
  const base = `${baseURL}/${postURL}`;
  if (!params) return base;

  const query = parseQueryParams(params);
  return `${base}${query}`;
};

export const endpoints = {
  // Campaigns
  campaigns: (params?: QueryParams) => generateUrlWithParams(CAMPAIGNS, params),
  campaign: (id: string) => `${baseURL}/${CAMPAIGNS}/${id}`,
  createCampaign: `${baseURL}/${CAMPAIGNS}`,
  updateCampaign: (id: string) => `${baseURL}/${CAMPAIGNS}/${id}`,
  deleteCampaign: (id: string) => `${baseURL}/${CAMPAIGNS}/${id}`,
  suspendCampaign: (id: string) => `${baseURL}/${CAMPAIGNS}/${id}/${SUSPEND}`,
  resumeCampaign: (id: string) => `${baseURL}/${CAMPAIGNS}/${id}/${RESUME}`,
  endCampaign: (id: string) => `${baseURL}/${CAMPAIGNS}/${id}/${END}`,
  restartCampaign: (id: string) => `${baseURL}/${CAMPAIGNS}/${id}/${RESTART}`,

  // Stats
  dashboardStats: (params?: QueryParams) =>
    generateUrlWithParams(STATS, params),
  campaignStats: (id: string) => `${baseURL}/${STATS}/${CAMPAIGN}/${id}`,

  // Websites
  websites: (params?: QueryParams) => generateUrlWithParams(WEBSITES, params),
  website: (id: string) => `${baseURL}/${WEBSITES}/${id}`,
  createWebsite: `${baseURL}/${WEBSITES}`,
  updateWebsite: (id: string) => `${baseURL}/${WEBSITES}/${id}`,
  deleteWebsite: (id: string) => `${baseURL}/${WEBSITES}/${id}`,
  websitePages: (id: string) => `${baseURL}/${WEBSITES}/${id}/pages`,
  websitePage: (id: string, pageId: string) =>
    `${baseURL}/${WEBSITES}/${id}/pages/${pageId}`,
  createWebsitePage: (id: string) => `${baseURL}/${WEBSITES}/${id}/pages`,
  updateWebsitePage: (id: string, pageId: string) =>
    `${baseURL}/${WEBSITES}/${id}/pages/${pageId}`,
  deleteWebsitePage: (id: string, pageId: string) =>
    `${baseURL}/${WEBSITES}/${id}/pages/${pageId}`,

  // Popups
  popups: (params?: QueryParams) => generateUrlWithParams(POPUPS, params),
  popup: (id: string) => `${baseURL}/${POPUPS}/${id}`,
  createPopup: `${baseURL}/${POPUPS}`,
  updatePopup: (id: string) => `${baseURL}/${POPUPS}/${id}`,
  deletePopup: (id: string) => `${baseURL}/${POPUPS}/${id}`,

  // Campaign popups
  campaignPopups: (campaignId: string, params?: QueryParams) =>
    generateUrlWithParams(`${CAMPAIGNS}/${campaignId}/${POPUPS}`, params),
  campaignPopup: (campaignId: string, id: string) =>
    `${baseURL}/${CAMPAIGNS}/${campaignId}/${POPUPS}/${id}`,
  createCampaignPopup: (campaignId: string) =>
    `${baseURL}/${CAMPAIGNS}/${campaignId}/${POPUPS}`,
  updateCampaignPopup: (campaignId: string, id: string) =>
    `${baseURL}/${CAMPAIGNS}/${campaignId}/${POPUPS}/${id}`,
  deleteCampaignPopup: (campaignId: string, id: string) =>
    `${baseURL}/${CAMPAIGNS}/${campaignId}/${POPUPS}/${id}`,

  // Users
  users: `${baseURL}/${USERS}`,
  user: (id: string) => `${baseURL}/${USERS}/${id}`,
  createUser: `${baseURL}/${USERS}`,
  deleteUser: (id: string) => `${baseURL}/${USERS}/${id}`,
  updateUser: (id: string) => `${baseURL}/${USERS}/${id}`,

  // Profile
  profile: `${baseURL}/${USER}/${PROFILE}`,

  // login
  login: `${baseURL}/${AUTH}/${LOGIN}`,
  // Me
  me: `${baseURL}/${AUTH}/${ME}`,

  // Editor
  editor: `${baseURL}/${EDITOR}/${IFRAME}`,
};
