import { useNavigate } from 'react-router-dom';
import { useUserStore } from '@/stores/user';
import Joyride from 'react-joyride';
import { useTranslation } from '@/hooks/useTranslation';
import { useMemo } from 'react';

export function MainWalkthrough() {
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  const setWasMainWalkthroughShown = useUserStore(
    (state) => state.setWasMainWalkthroughShown
  );

  const steps = useMemo(
    () => [
      {
        target: '#navbar',
        content: t('stepNavbar'),
      },
      {
        target: '#dashboard-sidebar-item',
        content: t('stepSidebarDashboard'),
      },
      {
        target: '#dashboard-statistics-top',
        content: t('stepDashboardStatsTop'),
      },
      {
        target: '#dashboard-statistics-bottom',
        content: t('stepDashboardStatsBottom'),
      },
      {
        target: '#websites-sidebar-item',
        content: t('stepSidebarWebsites'),
      },
      {
        target: '#website-table',
        content: t('stepWebsitesTable'),
      },
      {
        target: '#campaigns-sidebar-item',
        content: t('stepSidebarCampaigns'),
      },
      {
        target: '#campaign-table',
        content: t('stepCampaignsTable'),
      },
      {
        target: '#popups-sidebar-item',
        content: t('stepSidebarPopups'),
      },
      {
        target: '#popup-selectors',
        content: t('stepPopupsSelectors'),
      },
      {
        target: '#popup-templates',
        content: t('stepPopupsTemplates'),
      },
      {
        target: '#additional-informations-button',
        content: t('stepSidebarAddiitionalInfo'),
      },
    ],
    [t]
  );

  return (
    <Joyride
      steps={steps}
      locale={{
        back: t('back'),
        close: t('close'),
        last: t('last'),
        next: t('next'),
        open: t('open'),
        skip: t('skip'),
      }}
      callback={(data) => {
        if (data.step.target === '#websites-sidebar-item') {
          navigate('/websites');
        }

        if (data.step.target === '#campaigns-sidebar-item') {
          navigate('/campaigns');
        }

        if (data.step.target === '#popups-sidebar-item') {
          navigate('/popups');
        }

        if (data.status === 'finished') {
          setWasMainWalkthroughShown();
        }
      }}
      showSkipButton
      showProgress
      continuous
    />
  );
}
