import { Outlet } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Sidebar } from './components/layout/Sidebar';
import { Navbar } from './components/Navbar';
import RequireAuth from './components/layout/RequireAuth';
import { ModelsContainer } from './components/layout/ModalsContainer';
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';
import {
  GROWTHBOOK_API_HOST,
  GROWTHBOOK_CLIENT_KEY,
  IS_GROWTBOOK_DEV_MODE_ENABLED,
  IS_GROWTHBOOK_AVAILABLE,
} from './utils/growthbook';
import { useEffect } from 'react';

const growthbook = new GrowthBook({
  apiHost: GROWTHBOOK_API_HOST,
  clientKey: GROWTHBOOK_CLIENT_KEY,
  enableDevMode: IS_GROWTBOOK_DEV_MODE_ENABLED,
  trackingCallback: (experiment, result) => {
    // TODO: Use your real analytics tracking system
    console.log('Viewed Experiment', {
      experimentId: experiment.key,
      variationId: result.key,
    });
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export function AppLayout() {
  console.log(
    'import.meta.env.VITE_GROWTHBOOK_API_HOST',
    import.meta.env.VITE_GROWTHBOOK_API_HOST
  );
  console.log(
    'import.meta.env.VITE_GROWTHBOOK_CLIENT_KEY',
    import.meta.env.VITE_GROWTHBOOK_CLIENT_KEY
  );

  useEffect(() => {
    if (IS_GROWTHBOOK_AVAILABLE) {
      growthbook.init({ streaming: true });
    }
  }, []);

  return (
    <GrowthBookProvider growthbook={growthbook}>
      <RequireAuth>
        <QueryClientProvider client={queryClient}>
          <div className="flex min-h-screen w-full flex-col bg-muted/40">
            <Sidebar />
            <div className="flex flex-col sm:gap-4 sm:pl-14">
              <Navbar />
              <div className="h-full w-full py-4 px-2 lg:py-8 lg:px-4 overflow-x-scroll">
                <Outlet />
              </div>
            </div>
            <div
              className="fixed -z-10 top-0 left-0 w-full h-full opacity-10"
              style={{
                backgroundImage:
                  'repeating-linear-gradient(45deg, #0369a1 0, #0369a1 2px, transparent 0, transparent 50%)',
                backgroundSize: '8px 8px',
                backgroundColor: '#f0f9ff',
              }}
            />
            <ModelsContainer />
          </div>
        </QueryClientProvider>
      </RequireAuth>
    </GrowthBookProvider>
  );
}
