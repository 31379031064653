import { Check, Moon, Sun } from 'lucide-react';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { useTranslation } from '@/hooks/useTranslation';
import { useTheme } from './ThemeProvider';
import { TooltipWrapper } from '../TooltipWrapper';

interface ColorSwitchListItemProps {
  isSelected: boolean;
  label: string;
}

const ColorSwitchListItem = ({
  isSelected,
  label,
}: ColorSwitchListItemProps) => {
  return (
    <div className="w-full flex items-center justify-between">
      {label}
      {isSelected && <Check className="w-4 h-4" />}
    </div>
  );
};

export function ColorSwitch() {
  const { setTheme, theme } = useTheme();
  const { t } = useTranslation('common');

  return (
    <DropdownMenu>
      <TooltipWrapper label={t('toggleTheme')} side="bottom">
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" size="icon">
            <Sun className="h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0" />
            <Moon className="absolute h-[1.2rem] w-[1.2rem] rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100" />
            <span className="sr-only">{t('toggleTheme')}</span>
          </Button>
        </DropdownMenuTrigger>
      </TooltipWrapper>
      <DropdownMenuContent align="end">
        <DropdownMenuItem onClick={() => setTheme('light')}>
          <ColorSwitchListItem
            isSelected={theme === 'light'}
            label={t('light')}
          />
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => setTheme('dark')}>
          <ColorSwitchListItem
            isSelected={theme === 'dark'}
            label={t('dark')}
          />
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => setTheme('system')}>
          <ColorSwitchListItem
            isSelected={theme === 'system'}
            label={t('system')}
          />
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
