import {
  InvalidateQueryFilters,
  QueryFilters,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { SendRequestResponse, useApi } from '@/hooks/useApi';
import { WEBSITES } from '@/queryKeys';
import { endpoints } from '@/endpoints';
import { WebsiteBE } from '@/types/website';
import { mapWebsiteDTOToBEForCreate } from '@/mappers/mapWebsiteDTO';

const WEBSITES_QUERY_KEY = [WEBSITES];

export const useCreateWebsite = <Website>({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: () => void;
}) => {
  const queryClient = useQueryClient();

  const { sendRequest } = useApi<WebsiteBE>({
    url: endpoints.websites(),
    method: 'post',
  });

  return useMutation<
    SendRequestResponse<WebsiteBE>,
    Error,
    Partial<Website>,
    { previousCampaigns: WebsiteBE[] | undefined }
  >({
    mutationFn: async (data: Partial<Website>) => {
      const mappedData = mapWebsiteDTOToBEForCreate(data);
      const response = await sendRequest(mappedData);

      if (response?.error) {
        throw new Error(response.error.message);
      }

      return response;
    },
    // With onMutate, we can update the cache before the mutation is executed
    onMutate: async (data: Partial<Website>) => {
      await queryClient.cancelQueries(WEBSITES_QUERY_KEY as QueryFilters);

      const previousCampaigns = queryClient.getQueryData<WebsiteBE[]>([
        WEBSITES,
      ]);

      queryClient.setQueryData<Partial<WebsiteBE>[]>([WEBSITES], (old) => {
        if (old) {
          return [
            ...old,
            {
              ...data,
              id: 9999,
              created: new Date().toISOString(),
              updated: new Date().toISOString(),
              accessCode: '',
              processingStatus: 'draft',
              isUnasPage: Number(false),
              displayOnAllPage: Number(false),
              disabled: Number(false),
              autoDisabled: Number(false),
            },
          ];
        }
        return [data];
      });

      return { previousCampaigns };
    },
    onError: (_, __, context) => {
      console.log('ERROR');
      if (context?.previousCampaigns) {
        queryClient.setQueryData([WEBSITES], context.previousCampaigns);
      }
      onError?.();
    },
    onSuccess: () => {
      queryClient.invalidateQueries(
        WEBSITES_QUERY_KEY as InvalidateQueryFilters
      );
      onSuccess?.();
    },
  });
};
