import {
  InvalidateQueryFilters,
  QueryFilters,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { SendRequestResponse, useApi } from '@/hooks/useApi';
import { CAMPAIGN } from '@/queryKeys';
import { endpoints } from '@/endpoints';
import { CampaignBE } from '@/types/campaign';
import { mapCampaignDTOToBEForUpdate } from '@/mappers/mapCampaignDTO';

export const useUpdateCampaign = <Campaign>({
  id,
  onSuccess,
  onError,
}: {
  id: string;
  onSuccess?: () => void;
  onError?: () => void;
}) => {
  const queryClient = useQueryClient();

  const { sendRequest } = useApi<CampaignBE>({
    url: endpoints.campaign(id),
    method: 'put',
  });

  return useMutation<
    SendRequestResponse<CampaignBE>,
    Error,
    { id: number; data: Partial<Campaign> },
    { previousCampaigns: CampaignBE[] | undefined }
  >({
    mutationFn: async ({ id, data }) => {
      const mappedData = mapCampaignDTOToBEForUpdate(data);
      const response = await sendRequest({ id, ...mappedData });

      if (response?.error) {
        throw new Error(response.error.message);
      }

      return response;
    },
    onMutate: async ({ id, data }) => {
      await queryClient.cancelQueries([CAMPAIGN, id] as QueryFilters);

      const previousCampaigns = queryClient.getQueryData<CampaignBE[]>([
        CAMPAIGN,
        id,
      ]);

      queryClient.setQueryData<CampaignBE[]>([CAMPAIGN, id], (old) => {
        if (old) {
          return old.map((campaign) =>
            campaign.id === id ? { ...campaign, ...data } : campaign
          );
        }
        return old;
      });

      return { previousCampaigns };
    },
    onError: (_, __, context) => {
      if (context?.previousCampaigns) {
        queryClient.setQueryData([CAMPAIGN, id], context.previousCampaigns);
      }
      onError?.();
    },
    onSuccess: () => {
      queryClient.invalidateQueries([CAMPAIGN, id] as InvalidateQueryFilters);
      onSuccess?.();
    },
  });
};
