import { useMenuStore } from '@/stores/menu';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const withPathnameWatcher = (WrappedComponent: React.ComponentType) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (props: any) => {
    const location = useLocation();
    const setActiveItem = useMenuStore((state) => state.setActiveItem);

    useEffect(() => {
      // Function to execute on pathname change
      const handlePathnameChange = () => {
        console.log('Pathname changed to:', location.pathname);
        setActiveItem(location.pathname);
      };

      handlePathnameChange(); // Initial call on mount
    }, [location.pathname, setActiveItem]);

    return <WrappedComponent {...props} />;
  };
};
