import { useQuery } from '@tanstack/react-query';
import { useApi } from '@/hooks/useApi';
import { endpoints } from '@/endpoints';
import { CAMPAIGN } from '@/queryKeys';
import { CampaignBE } from '@/types/campaign';
import { mapCampaignBEsToDTO } from '@/mappers/mapCampaignDTO';

interface UseGetCampaignParams {
  campaignId: string;
  enabled?: boolean;
}

export const useGetCampaign = ({
  campaignId,
  enabled = true,
}: UseGetCampaignParams) => {
  const { sendRequest } = useApi({
    url: endpoints.campaign(campaignId),
    method: 'get',
  });

  return useQuery({
    queryKey: [CAMPAIGN, campaignId],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const response = await sendRequest();

      if (response.error) {
        throw new Error(response.error.message);
      }

      let data: CampaignBE | null = null;

      if (response?.data) {
        data = response.data as CampaignBE;
      }

      return data ? mapCampaignBEsToDTO(data) : null;
    },
    enabled,
  });
};
