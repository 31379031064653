import { useTranslation } from '@/hooks/useTranslation';
import { Modal } from './Modal';
import { useModalContext } from '@/hooks/useModalContext';
import { HelpModalBase } from './HelpModalBase';

export const CampaignOnboardingAndHelpModal = () => {
  const { t } = useTranslation('common');
  const { closeModal } = useModalContext();

  return (
    <Modal modalType="campaignOnboardingAndHelp" title={t('help')}>
      <HelpModalBase onClose={() => closeModal('campaignOnboardingAndHelp')}>
        <h2>Onboarding and help modal for the Website</h2>
      </HelpModalBase>
    </Modal>
  );
};
