import { Button } from './ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from './ui/dropdown-menu';
import { useTranslation } from '../hooks/useTranslation';
import { Language, useLanguageStore } from '../stores/language';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FlagIcon } from './FlagIcon';
import { Check } from 'lucide-react';
import i18n from '../i18n';
import clsx from 'clsx';

const flags: Record<Language, { component: JSX.Element; label: string }> = {
  'en-US': {
    component: <FlagIcon src="/flags/uk.png" alt={i18n.t('common:english')} />,
    label: 'common:english',
  },
  'hu-HU': {
    component: (
      <FlagIcon src="/flags/hungary.png" alt={i18n.t('common:hungarian')} />
    ),
    label: 'common:hungarian',
  },
};

interface LanguageDropdownItemProps {
  onClick: () => void;
  code: Language;
  selected?: boolean;
}

interface LanguageSelectorProps {
  triggerClassName?: string;
  contentClassName?: string;
  showFullLanguageName?: boolean;
}

const LanguageDropdownItem = ({
  onClick,
  code,
  selected,
}: LanguageDropdownItemProps) => {
  const { t } = useTranslation();

  return (
    <DropdownMenuItem
      className="flex gap-2 items-center justify-between"
      onClick={onClick}
    >
      <div className="flex gap-2 items-center">
        {flags[code].component}
        {t(flags[code].label)}
      </div>
      {selected && <Check className="w-3 h-3" />}
    </DropdownMenuItem>
  );
};

export const LanguageSelector = ({
  triggerClassName,
  contentClassName,
  showFullLanguageName = false,
}: LanguageSelectorProps) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language as Language;
  const currentLanguageCode = useMemo(
    () => currentLanguage.slice(0, 2),
    [currentLanguage]
  );

  const [triggerWidth, setTriggerWidth] = useState<number | null>(null);
  const triggerRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (triggerRef.current) {
      setTriggerWidth(triggerRef.current.offsetWidth);
    }
  }, []);

  const changeLanguage = useCallback(
    (lng: Language) => {
      const setLanguage = useLanguageStore.getState().setLanguage;
      if (currentLanguage === lng) {
        return;
      }
      i18n.changeLanguage(lng);
      setLanguage(lng);
    },
    [currentLanguage, i18n]
  );

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          ref={triggerRef}
          variant="ghost"
          size="icon"
          className={clsx('uppercase flex gap-1 w-16', triggerClassName)}
        >
          {showFullLanguageName ? (
            <>
              {flags[currentLanguage].component}
              {t(flags[currentLanguage].label)}
            </>
          ) : (
            <>
              {flags[currentLanguage].component}
              {currentLanguageCode}
              <span className="sr-only">{t('common:selectLanguage')}</span>
            </>
          )}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align="end"
        className={clsx(contentClassName)}
        style={{ width: triggerWidth ? `${triggerWidth}px` : 'auto' }}
      >
        {Object.entries(flags).map(([code]) => (
          <LanguageDropdownItem
            key={code}
            onClick={() => changeLanguage(code as Language)}
            code={code as Language}
            selected={currentLanguage === code}
          />
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
