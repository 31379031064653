import { useTranslation } from '@/hooks/useTranslation';
import { Modal } from './Modal';
import { useEffect, useState } from 'react';
import { useModalContext } from '@/hooks/useModalContext';
import { toast } from 'sonner';
import { Campaign } from '@/types/campaign';
import { useCampaignStore } from '@/stores/campaign';
import { useUpdateCampaign } from '@/hooks/campaign/useUpdateCampaign';
import { CampaignForm } from './forms/CampaignForm';
import { useGetWebsiteList } from '@/hooks/website/useGetWebsiteList';
import { useGetCampaign } from '@/hooks/campaign/useGetCampaign';
import { LoadingIndicator } from '../common/LoadingIndicator';

export const UpdateCampaignModal = () => {
  const { t } = useTranslation('common');
  const { closeModal, isModalOpen } = useModalContext();
  const selectedCampaignId =
    useCampaignStore((state) => state.selectedCampaignId) ?? '';
  const setSelectedCampaign = useCampaignStore(
    (state) => state.setSelectedCampaign
  );

  const { data: campaign, isLoading: campaignLoading } = useGetCampaign({
    campaignId: selectedCampaignId,
  });

  const { data: websites, isLoading: websitesLoading } = useGetWebsiteList({
    enabled: isModalOpen,
  });
  const websitesData = websites || [];

  const { mutate: updateCampaign } = useUpdateCampaign({
    id: selectedCampaignId,
    onSuccess: () => {
      toast.success(t('campaignUpdatedSuccessfully'));
      handleClose();
    },
    onError: () => {
      toast.error(t('failedToUpdateCampaign'));
    },
  });

  const [campaingData, setCampaignData] = useState<Campaign>();

  useEffect(() => {
    if (campaign) {
      setCampaignData(campaign);
    }
  }, [campaign]);

  const handleClose = () => {
    setSelectedCampaign(null);
    closeModal('updateCampaign');
  };

  const handleSave = () => {
    if (!campaingData?.id) return;

    updateCampaign({
      id: Number(campaingData.id),
      data: campaingData,
    });
  };

  const handleChangeCampaign = (data: Campaign) => {
    setCampaignData(data);
  };

  return (
    <Modal modalType="updateCampaign" title={t('editCampaign')}>
      <div className="space-y-4">
        {!campaingData || campaignLoading || websitesLoading ? (
          <div className="w-full flex items-center justify-center">
            <LoadingIndicator />
          </div>
        ) : (
          <CampaignForm
            data={campaingData}
            websites={websitesData}
            onChange={handleChangeCampaign}
            onClose={handleClose}
            onSubmit={handleSave}
            isLoading={campaignLoading || websitesLoading}
          />
        )}
      </div>
    </Modal>
  );
};
