export const AUTH = 'auth';
export const CAMPAIGN = 'campaign';
export const CAMPAIGNS = 'campaigns';
export const CAMPAIGN_POPUP = 'campaign_popup';
export const CAMPAIGN_STATS = 'campaign_stats';
export const DASHBOARD_STATS = 'dashboard_stats';
export const EDITOR = 'editor';
export const END = 'end';
export const IFRAME = 'iframe';
export const LOGIN = 'login';
export const ME = 'me';
export const PAGES = 'pages';
export const POPUPS = 'popups';
export const PROFILE = 'profile';
export const RESTART = 'restart';
export const RESUME = 'resume';
export const STATS = 'stats';
export const SUSPEND = 'suspend';
export const USER = 'user';
export const USERS = 'users';
export const WEBSITES = 'websites';
