function NotFoundDisplay() {
  return (
    <div className="w-full h-full flex flex-col items-center justify-center gap-4">
      <h1 className="text-4xl font-bold">404</h1>
      <p className="text-lg font-medium">Page not found</p>
    </div>
  );
}

export default NotFoundDisplay;
