import { Button } from './ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from './ui/dropdown-menu';
import { useTranslation } from '../hooks/useTranslation';
import { SquareLibrary } from 'lucide-react';
import { TooltipWrapper } from './TooltipWrapper';
import clsx from 'clsx';

export const AdditionalInformationsButton = () => {
  const { t } = useTranslation('common');

  const links = [
    {
      label: t('support'),
      href: '/#',
      disabled: true,
    },
    {
      label: t('helpCenter'),
      href: '/#',
      disabled: true,
    },
    {
      label: t('privacyPolicy'),
      href: '/#',
      disabled: true,
    },
    {
      label: t('termsOfService'),
      href: '/#',
      disabled: true,
    },
  ];

  return (
    <DropdownMenu>
      <TooltipWrapper label={t('informations')} side="right">
        <DropdownMenuTrigger asChild>
          <Button
            variant="ghost"
            size="icon"
            title={t('common:selectLanguage')}
            className="h-9 w-9 uppercase flex rounded-full text-white hover:text-sky-700"
            id="additional-informations-button"
          >
            <SquareLibrary className="h-5 w-5" />
            <span className="sr-only">{t('informations')}</span>
          </Button>
        </DropdownMenuTrigger>
      </TooltipWrapper>
      <DropdownMenuContent align="start" alignOffset={30}>
        {links.map((link) => (
          <DropdownMenuItem key={link.label}>
            <a
              href={link.disabled ? undefined : link.href}
              target="_blank"
              rel="noopener noreferrer"
              className={clsx(
                'block w-full rounded-full hover:text-foreground',
                {
                  'cursor-not-allowed text-muted-foreground': link.disabled,
                }
              )}
            >
              {link.label}
            </a>
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
