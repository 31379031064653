export const GROWTHBOOK_API_HOST = import.meta.env.VITE_GROWTHBOOK_API_HOST;

export const GROWTHBOOK_CLIENT_KEY = import.meta.env.VITE_GROWTHBOOK_CLIENT_KEY;

export const IS_GROWTBOOK_DEV_MODE_ENABLED =
  import.meta.env.VITE_GROWTHBOOK_ENABLE_DEV_MODE === 'true';

export const IS_GROWTHBOOK_SUBSCRIBE_TO_CHANGES =
  import.meta.env.VITE_GROWTHBOOK_SUBSCRIBE_TO_CHANGES === 'true';

export const IS_GROWTHBOOK_AVAILABLE =
  GROWTHBOOK_API_HOST !== '' && GROWTHBOOK_CLIENT_KEY !== '';
