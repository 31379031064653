/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios';
import { useCallback, useState, useRef } from 'react';
import { useAuthContext } from './useAuth';
import { useNavigate } from 'react-router-dom';
import { AnyObject } from '@/types/common';

// export const baseURL = import.meta.env.API_URL as string;
/* export const baseURL = 'https://64bc2b0b7b33a35a44471837.mockapi.io' as string; */
export const baseURL = (import.meta.env.VITE_API_URL ||
  'http://localhost:3000') as string;

const client = axios.create({
  baseURL,
});

type Props<Data> = {
  data: Data | undefined;
  error: AxiosError | undefined;
  headers: AxiosResponse<unknown, Data>['headers'] | undefined;
};

export type SendRequestResponse<Data> = {
  data: Props<Data>['data'];
  error: Props<Data>['error'];
  headers: Props<Data>['headers'];
};

export type ListResponseType<T> = {
  count: number;
  per_page: number;
  data: T[];
};

export type Api<Data> = {
  loading: boolean;
  cancel: () => void;
  sendRequest: (
    data?: Data | AnyObject,
    requestConfig?: Partial<ApiConfig>
  ) => Promise<SendRequestResponse<Data>>;
};

export type ApiConfig = Omit<
  AxiosRequestConfig,
  'method' | 'url' | 'data' | 'signal'
> & {
  url: string;
  method: AxiosRequestConfig['method'];
};

export function useApi<Data>(config: ApiConfig): Api<Data> {
  const auth = useAuthContext();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const controller = useRef(new AbortController());

  const sendRequest = useCallback(
    async (
      requestData?: Data | AnyObject,
      requestConfig?: Partial<ApiConfig>
    ): Promise<SendRequestResponse<Data>> => {
      let data,
        responseHeaders,
        error = undefined;
      setLoading(true);
      try {
        const headers = config.headers || {};

        if (!headers?.Authorization && auth.isLoggedIn && auth.token) {
          headers.Authorization = `Bearer ${auth.token}`;
        }

        if (requestConfig?.headers?.Authorization) {
          headers.Authorization = requestConfig.headers.Authorization;
          delete requestConfig.headers.Authorization;
        }

        if (auth.client) {
          headers['x-running-client'] = auth.client;
        }

        const reqData = {
          ...config,
          url: requestConfig?.url ?? config.url,
          method: requestConfig?.method ?? config.method,
          signal: controller.current.signal,
          data: requestData,
          headers,
        };

        switch (config.method) {
          case 'get':
            reqData.params = requestData;
            break;
          default:
            reqData.data = requestData;
            break;
        }

        const response = await client(reqData);

        data = response.data;
        responseHeaders = response.headers;
      } catch (e: any) {
        error = e;
      } finally {
        setLoading(false);
      }

      if (error && error.response && error.response.status) {
        if (error.response.status === 401) {
          auth.logout(() => {
            navigate('/login');
          });
        }
      }

      return { data, error, headers: responseHeaders };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(config), auth, navigate]
  );

  const cancel = useCallback(() => {
    controller.current.abort();
  }, [controller]);

  return {
    sendRequest,
    cancel,
    loading,
  };
}

export type CallApi<Data> = Pick<Api<Data>, 'cancel'> & {
  sendRequest: (requestData?: AnyObject) => Promise<Data>;
};

export function apiRequest<T>(config: ApiConfig): CallApi<T> {
  const token = localStorage.getItem('token');
  const selectedClient = localStorage.getItem('selected-client');
  const headers = config.headers || {};
  const controller = new AbortController();

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  if (selectedClient) {
    headers['x-running-client'] = selectedClient;
  }

  const sendRequest = async (requestData?: AnyObject) => {
    const response = await client({
      ...config,
      data: requestData,
      headers,
    });

    return response.data;
  };

  return {
    cancel: () => {
      controller.abort();
    },
    sendRequest,
  };
}

export function getTusdUrl() {
  // const tusdUrl = import.meta?.env?.TUSD_URL;

  // return `${tusdUrl ? tusdUrl : 'http://localhost:1080'}/files/`;
  return 'http://localhost:1080/files/';
}
