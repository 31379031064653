import { useQuery } from '@tanstack/react-query';
import { useApi } from '@/hooks/useApi';
import { endpoints } from '@/endpoints';
import { WEBSITES } from '@/queryKeys';
import { WebsiteBE } from '@/types/website';
import { mapWebsiteBEsToDTO } from '@/mappers/mapWebsiteDTO';

interface UseGetWebsiteListParams {
  enabled?: boolean;
}

export const useGetWebsiteList = ({
  enabled = true,
}: UseGetWebsiteListParams) => {
  const { sendRequest } = useApi({
    url: endpoints.websites(),
    method: 'get',
  });

  return useQuery({
    queryKey: [WEBSITES],
    queryFn: async () => {
      const response = await sendRequest();

      let data: WebsiteBE[] = [];

      if (response?.data) {
        data = response.data as WebsiteBE[];
      }

      return mapWebsiteBEsToDTO(data);
    },
    enabled,
  });
};
