// import { isAxiosError } from 'axios';

// import { ListResponseType, apiRequest } from '@/hooks/useApi';
// import { UserType } from '@/hooks/useAuth';
// import { ClientType } from '@/schemas/client';

/* async function wrapper<T>(
  request: () => Promise<T | ListResponseType<T>>,
  listBased = false
) {
  let data: T | T[] | null = listBased ? [] : null;

  try {
    const responseData = await request();
    data = listBased
      ? (responseData as ListResponseType<T>).data
      : (responseData as T);
  } catch (error) {
    if (!isAxiosError(error)) {
      throw error;
    }
  }

  return data;
} */

export type AppLayoutLoaderType = {
  // user: UserType | null;
  // clients: ClientType[];
};

export const appLayoutLoader = async () => {
  /* const { sendRequest: getProfile } = apiRequest<UserType>({
    url: '/user/profile',
    method: 'get',
  }); */

  /* const { sendRequest: getClients } = apiRequest<ClientType[]>({
    url: '/user/clients',
    method: 'get',
  }); */

  // let data: UserType | null = null;

  // try {
  //   data = await sendRequest();
  // } catch (error) {
  //   if (!isAxiosError(error)) {
  //     throw error;
  //   }
  // }

  const user = null; // await wrapper(getProfile);

  const clients = null; // await wrapper(getClients);

  return {
    user,
    clients,
  };
};
