import { Menu } from 'lucide-react';
import { Button } from '../ui/button';
import { useMenuStore } from '@/stores/menu';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import { useTranslation } from '@/hooks/useTranslation';
import { Sheet, SheetContent, SheetTrigger } from '../ui/sheet';

export const SidebarMobile = () => {
  const { t } = useTranslation('common');
  const items = useMenuStore((state) => state.items);

  const links = [
    {
      label: t('support'),
      href: '/#',
    },
    {
      label: t('helpCenter'),
      href: '/#',
    },
    {
      label: t('privacyPolicy'),
      href: '/#',
    },
    {
      label: t('termsOfService'),
      href: '/#',
    },
  ];

  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button variant="outline" size="icon" className="shrink-0 md:hidden">
          <Menu className="h-5 w-5" />
          <span className="sr-only">{t('toggleNavigationMenu')}</span>
        </Button>
      </SheetTrigger>
      <SheetContent side="left" className="w-[20rem] h-screen">
        <div className="w-full h-full">
          <div className="flex items-center justify-center gap-2">
            <img
              src="/cc_vertical_128.png"
              alt="ConversionCraft"
              className="h-10"
            />
            <img
              src="/cc_vertical_title.png"
              alt="ConversionCraft"
              className="h-6"
            />
          </div>
          <nav className="flex flex-col h-full justify-between py-8">
            <div className="flex flex-col gap-2 font-medium">
              {items.map((item) => (
                <NavLink
                  key={item.label}
                  to={item.href}
                  className={clsx(
                    'flex p-2 gap-2 items-center justify-start rounded-md',
                    {
                      'bg-primary text-white': item.active,
                      'hover:bg-accent hover:text-muted-foreground':
                        !item.active,
                    }
                  )}
                >
                  {item.icon}
                  <span className="hover:text-muted-foreground">
                    {t(item.label)}
                  </span>
                </NavLink>
              ))}
            </div>
            <div className="flex flex-col w-full items-center gap-4 px-2 sm:py-5">
              {links.map((link) => (
                <NavLink
                  key={link.label}
                  to={link.href}
                  className="block w-full rounded-full hover:text-foreground"
                >
                  {link.label}
                </NavLink>
              ))}
            </div>
          </nav>
        </div>
      </SheetContent>
    </Sheet>
  );
};
