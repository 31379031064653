import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import { produce } from 'immer';

export type Language = 'en-US' | 'hu-HU';

export type LanguageStore = {
  language: Language;
  setLanguage: (language: Language) => void;
};

export const useLanguageStore = create<LanguageStore>()(
  devtools(
    persist(
      (set) => ({
        language: 'en-US',
        setLanguage: (language: Language) => {
          set(
            produce((state: LanguageStore): void => {
              state.language = language;
            })
          );
        },
      }),
      {
        name: 'language',
        storage: createJSONStorage(() => localStorage),
      }
    )
  )
);
