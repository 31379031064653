import { CircleUser } from 'lucide-react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from './ui/dropdown-menu';
import { Button } from './ui/button';
import { useTranslation } from '@/hooks/useTranslation';
import { useAuthContext } from '@/hooks/useAuth';
import { useCallback } from 'react';
import { TooltipWrapper } from './TooltipWrapper';
import { useNavigate } from 'react-router-dom';

export const UserNav = () => {
  const { t } = useTranslation('common');
  const { logout } = useAuthContext();
  const navigate = useNavigate();

  const handleLogout = useCallback(async () => {
    await logout();
  }, [logout]);

  return (
    <DropdownMenu>
      <TooltipWrapper label={t('toggleUserMenu')} side="bottom">
        <DropdownMenuTrigger asChild>
          <Button variant="secondary" size="icon" className="rounded-full">
            <CircleUser className="h-5 w-5" />
            <span className="sr-only">{t('toggleUserMenu')}</span>
          </Button>
        </DropdownMenuTrigger>
      </TooltipWrapper>
      <DropdownMenuContent align="end">
        <DropdownMenuLabel>{t('userMenu')}</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={() => navigate('/account')}>
          {t('myAccount')}
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => navigate('/settings')}>
          {t('settings')}
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => navigate('/billing')}>
          {t('billing')}
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={handleLogout}>
          {t('logout')}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
