import { ClipboardPaste } from 'lucide-react';
import { useTranslation } from '@/hooks/useTranslation';
import { TooltipWrapper } from './TooltipWrapper';

export const InputPasteButton = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation('common');

  return (
    <TooltipWrapper label={t('paste')} side="top">
      <ClipboardPaste
        className="absolute h-6 w-6 right-2 top-8 cursor-pointer text-foreground hover:text-muted-foreground"
        onClick={onClick}
      />
    </TooltipWrapper>
  );
};
