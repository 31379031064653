import { SidebarItem } from './SidebarItem';
import { useMenuStore } from '@/stores/menu';
import { AdditionalInformationsButton } from '../AdditionalInformationsButton';
import { useLocation } from 'react-router-dom';
import { useTheme } from './ThemeProvider';
import clsx from 'clsx';
import { BrainCircuit } from 'lucide-react';

const getStyleByState = (active: boolean) =>
  active
    ? 'bg-white text-sky-700 text-lg font-semibold md:text-base'
    : 'text-white hover:bg-accent text-accent-foreground transition-colors hover:text-sky-700';

export const Sidebar = () => {
  const { theme } = useTheme();
  const items = useMenuStore((state) => state.items);

  const location = useLocation();
  const isOnEditor = location.pathname.includes('/editor');

  if (isOnEditor) {
    return null;
  }

  const isAiMarketingScreenActive =
    location.pathname.includes('/ai-informations');

  return (
    <aside
      className={clsx(
        'fixed inset-y-0 left-0 hidden w-14 flex-col border-r sm:flex z-30',
        theme === 'dark'
          ? 'bg-gray-950'
          : 'bg-gradient-to-b from-sky-700 to-sky-400'
      )}
    >
      <nav className="flex flex-col items-center gap-4 px-2 sm:py-4">
        {items.map((item) => (
          <SidebarItem
            key={item.href}
            {...item}
            className={getStyleByState(item.active)}
          />
        ))}
      </nav>
      <nav className="mt-auto flex flex-col items-center gap-4 px-2 sm:py-5">
        <SidebarItem
          icon={<BrainCircuit className="h-5 w-5" />}
          label="howWeUseAI"
          href="/ai-informations"
          active={isAiMarketingScreenActive}
          className={getStyleByState(isAiMarketingScreenActive)}
        />
        <AdditionalInformationsButton />
      </nav>
    </aside>
  );
};
