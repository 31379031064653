import {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  KeyboardEvent,
} from 'react';
import { useModalContext } from '../../hooks/useModalContext';
import { ModalType, useModalStore } from '../../stores/modals';
import { Dialog, DialogContent, DialogTitle } from '../ui/dialog';

interface ModalContext {
  title?: string;
  modalType: ModalType;
  children: ReactNode;
  className?: string;
  onClose?: () => void;
}

export const Modal = ({
  title,
  children,
  modalType,
  onClose,
}: ModalContext) => {
  const { isModalOpen, closeModal } = useModalContext();
  const modals = useModalStore((store) => store.modals);
  const ref = useRef<HTMLInputElement>(null);

  const handleClose = useCallback(() => {
    closeModal(modalType);
    onClose?.();
  }, [closeModal, modalType, onClose]);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Escape') {
        handleClose();
      }
    },
    [handleClose]
  );

  useEffect(() => {
    if (ref.current) {
      ref.current.checked = modals[modalType];
    }
  }, [modalType, modals]);

  if (modals[modalType] === false) {
    return null;
  }

  return (
    <Dialog open={isModalOpen} onOpenChange={handleClose}>
      <DialogContent onKeyDown={handleKeyDown} className="max-w-sm md:max-w-xl">
        {title && (
          <DialogTitle className="text-lg font-medium leading-6 text-gray-900">
            {title}
          </DialogTitle>
        )}
        {children}
      </DialogContent>
    </Dialog>
  );
};
