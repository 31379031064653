import clsx from 'clsx';
import { Loader2 } from 'lucide-react';

interface LoadingIndicatorProps {
  className?: string;
  size?: 'small' | 'medium' | 'large';
}

export const LoadingIndicator = ({
  className,
  size = 'small',
}: LoadingIndicatorProps) => {
  return (
    <Loader2
      className={clsx('h-4 w-4 animate-spin', className, {
        'h-4 w-4': size === 'small',
        'h-6 w-6': size === 'medium',
        'h-8 w-8': size === 'large',
      })}
    />
  );
};
