import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { produce } from 'immer';
import { Campaign } from '@/types/campaign';

export type CampaignStore = {
  selectedCampaign: Campaign | null;
  selectedCampaignId: string | null;
  setSelectedCampaign: (website: Campaign | null) => void;
  setSelectedCampaignId: (id: string | null) => void;
};

export const useCampaignStore = create<CampaignStore>()(
  devtools(
    persist(
      (set) => ({
        selectedCampaign: null,
        selectedCampaignId: null,
        setSelectedCampaign: (campaign: Campaign | null) => {
          set(
            produce((state: CampaignStore): void => {
              state.selectedCampaign = campaign;
            })
          );
        },
        setSelectedCampaignId: (id: string | null) => {
          set(
            produce((state: CampaignStore): void => {
              state.selectedCampaignId = id;
            })
          );
        },
      }),
      {
        name: 'campaign',
      }
    )
  )
);
