import { useTranslation } from '@/hooks/useTranslation';
import { Modal } from './Modal';
import { useState } from 'react';
import { Label } from '../ui/label';
import { Input } from '../ui/input';
import { Checkbox } from '../ui/checkbox';
import { DialogFooter } from '../ui/dialog';
import { Button } from '../ui/button';
import { useModalContext } from '@/hooks/useModalContext';
import { InputPasteButton } from '../InputPasteButton';
import { handleCheckboxChange } from '@/lib/utils';

export const CreateWebsiteModal = () => {
  const { t } = useTranslation('common');
  const { closeModal } = useModalContext();

  const handleClose = () => {
    closeModal('createNewWebsite');
  };

  const [website, setWebsite] = useState('');
  const [websiteUrl, setWebsiteUrl] = useState('');
  const [sitemapUrl, setSitemapUrl] = useState('');
  const [unasWebsite, setUnasWebsite] = useState(false);
  const [unasApiKey, setUnasApiKey] = useState('');
  const [showPopups, setShowPopups] = useState(false);
  const [disableWebsite, setDisableWebsite] = useState(false);

  const handleSave = () => {
    /* const data = {
      website,
      websiteUrl,
      sitemapUrl,
      unasWebsite,
      unasApiKey,
      showPopups,
      disableWebsite,
    }; */
    // onSave(data);
  };

  const handlePaste = async (
    setValue: React.Dispatch<React.SetStateAction<string>>
  ) => {
    try {
      const text = await navigator.clipboard.readText();
      setValue(text);
    } catch (err) {
      console.error('Failed to read clipboard contents: ', err);
    }
  };

  return (
    <Modal modalType="createNewWebsite" title={t('createANewWebsite')}>
      <div className="space-y-4">
        <div className="relative">
          <Label htmlFor="website">{t('website')}</Label>
          <Input
            id="website"
            value={website}
            onChange={(e) => setWebsite(e.target.value)}
            className="!bg-white"
          />
          <InputPasteButton onClick={() => handlePaste(setWebsite)} />
        </div>
        <div className="relative">
          <Label htmlFor="website-url">{t('websiteURL')}</Label>
          <Input
            id="website-url"
            value={websiteUrl}
            onChange={(e) => setWebsiteUrl(e.target.value)}
          />
          <InputPasteButton onClick={() => handlePaste(setWebsiteUrl)} />
        </div>
        <div className="relative">
          <Label htmlFor="sitemap-url">{t('sitemapURL')}</Label>
          <Input
            id="sitemap-url"
            value={sitemapUrl}
            onChange={(e) => setSitemapUrl(e.target.value)}
          />
          <InputPasteButton onClick={() => handlePaste(setSitemapUrl)} />
        </div>
        <div className="flex items-center gap-2">
          <Checkbox
            id="unas-website"
            checked={unasWebsite}
            onCheckedChange={handleCheckboxChange(setUnasWebsite)}
          />
          <Label htmlFor="unas-website">{t('unasSystemWebsite')}</Label>
        </div>
        <p className="text-gray-500 text-xs italic">
          {t('unasSystemWebsiteInfo')}
        </p>
        <div className="relative">
          <Label htmlFor="unas-api-key">{t('unasAPIKey')}</Label>
          <Input
            id="unas-api-key"
            value={unasApiKey}
            onChange={(e) => setUnasApiKey(e.target.value)}
          />
          <InputPasteButton onClick={() => handlePaste(setUnasApiKey)} />
        </div>
        <p className="text-sm text-gray-500">{t('unasAPIKeyInfo')}</p>
        <div className="flex items-center gap-2">
          <Checkbox
            id="show-popups"
            checked={showPopups}
            onCheckedChange={handleCheckboxChange(setShowPopups)}
          />
          <Label htmlFor="show-popups">{t('popupsCanAppearOnAllPages')}</Label>
        </div>
        <div className="flex items-center gap-2">
          <Checkbox
            id="disable-website"
            checked={disableWebsite}
            onCheckedChange={handleCheckboxChange(setDisableWebsite)}
          />
          <Label htmlFor="disable-website">{t('blockWebsite')}</Label>
        </div>
        <DialogFooter className="mt-4">
          <Button variant="ghost" onClick={handleClose}>
            {t('close')}
          </Button>
          <Button onClick={handleSave} variant="conversionCraft">
            {t('save')}
          </Button>
        </DialogFooter>
      </div>
    </Modal>
  );
};
