import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import { TooltipProvider } from './components/ui/tooltip.tsx';

import './i18n';

import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from 'react-router-dom';
import NotFoundDisplay from './components/common/error/NotFoundDisplay.tsx';
import RootErrorBoundary from './components/common/error/RootErrorBoundary.tsx';
import { appLayoutLoader } from './appLayoutLoader.ts';
import WatchedAppLayout from './components/layout/WatchedAppLayout.tsx';
import { ModalProvider } from './providers/ModalProvider.tsx';
import Layout from './components/layout/Layout.tsx';

const wrappedCreateBrowserRouter = createBrowserRouter;

const router = wrappedCreateBrowserRouter([
  {
    id: 'root',
    Component: WatchedAppLayout,
    loader: appLayoutLoader,
    errorElement: <RootErrorBoundary />,
    children: [
      {
        path: '/',
        element: <Navigate to="/dashboard" />,
      },
      {
        path: '/dashboard',
        lazy: () => import('@/screens/DashboardScreen.tsx'),
      },
      {
        path: '/editor/iframe',
        lazy: () => import('@/screens/EditorScreen'),
      },
      {
        path: '/websites',
        children: [
          {
            path: '/websites',
            lazy: () => import('@/screens/website/WebsiteScreen.tsx'),
          },
          {
            path: '/websites:id/pages',
            lazy: () => import('@/screens/website/WebsitePagesScreen.tsx'),
          },
          {
            path: '/websites/pages/best',
            lazy: () =>
              import('@/screens/website/BestPerformingPagesScreen.tsx'),
          },
          {
            path: '/websites/pages/worst',
            lazy: () =>
              import('@/screens/website/WorstPerformingPagesScreen.tsx'),
          },
        ],
      },
      {
        path: '/campaigns',
        children: [
          {
            path: '/campaigns',
            lazy: () => import('@/screens/campaign/CampaignScreen.tsx'),
          },
          {
            path: '/campaigns/:campaignId/popups',
            lazy: () => import('@/screens/campaign/CampaignPopupsScreen.tsx'),
          },
          {
            path: '/campaigns/:campaignId/popups/:popupId/settings',
            lazy: () =>
              import('@/screens/campaign/CampaignPopupSettingsScreen.tsx'),
          },
          {
            path: '/campaigns/:campaignId/popups/:popupId/editor',
            lazy: () =>
              import('@/screens/campaign/CampaignPopupEditorScreen.tsx'),
          },
          {
            path: '/campaigns/:campaignId/popups/:popupId/stats',
            lazy: () =>
              import('@/screens/campaign/CampaignPopupStatsScreen.tsx'),
          },
          {
            path: '/campaigns/:campaignId/subscribers',
            lazy: () =>
              import('@/screens/campaign/CampaignSubscribersScreen.tsx'),
          },
          {
            path: '/campaigns/:campaignId/stats',
            lazy: () =>
              import('@/screens/campaign/CampaignStatisticsScreen.tsx'),
          },
          {
            path: '/campaigns/:campaignId/conversions',
            lazy: () =>
              import('@/screens/campaign/CampaignConversionsScreen.tsx'),
          },
        ],
      },
      {
        path: '/subscribers',
        children: [
          {
            path: '/subscribers',
            lazy: () => import('@/screens/subscribers/SubscribersScreen.tsx'),
          },
        ],
      },
      {
        path: '/popups',
        children: [
          {
            path: '/popups',
            lazy: () => import('@/screens/popup/PopupScreen.tsx'),
          },
        ],
      },
      {
        path: '/account',
        lazy: () => import('@/screens/MyAccountScreen.tsx'),
      },
      {
        path: '/settings',
        lazy: () => import('@/screens/SettingsScreen.tsx'),
      },
      {
        path: '/billing',
        lazy: () => import('@/screens/BillingScreen.tsx'),
      },
      {
        path: '/ai-informations',
        lazy: () => import('@/screens/AIMarketingScreen.tsx'),
      },
      {
        path: '*',
        element: <NotFoundDisplay />,
      },
    ],
  },
  {
    path: '/login',
    lazy: () => import('@/screens/LoginScreen.tsx'),
  },
  {
    path: '/registration',
    lazy: () => import('@/screens/RegistrationScreen'),
  },
  {
    path: '/forgotten',
    lazy: () => import('@/screens/ForgottenPasswordScreen'),
  },
  // TODO: create a 404 page
]);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Layout>
      <ModalProvider>
        <TooltipProvider>
          <RouterProvider router={router} />
        </TooltipProvider>
      </ModalProvider>
    </Layout>
  </React.StrictMode>
);
