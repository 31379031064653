import { ReactNode, useEffect, useState } from 'react';
import { useApi } from '@/hooks/useApi';
import { useAuthContext } from '@/hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { endpoints } from '@/endpoints';
import { UserType } from '@/types/user';

const RequireAuth = ({ children }: { children: ReactNode }) => {
  const { isLoggedIn } = useAuthContext();
  const navigate = useNavigate();
  const [hasError, setHasError] = useState(true);
  // const setUser = useUserStore((state: UserStore) => state.setUserRole);
  const { sendRequest, loading } = useApi<UserType>({
    url: endpoints.me,
    method: 'get',
  });

  useEffect(() => {
    const callSendRequest = async () => {
      if (!isLoggedIn) {
        return navigate('/login');
      }

      const { error } = await sendRequest();
      if (error) {
        setHasError(true);

        // 401 error doesn't need special handling here because the interceptor
        // handles this case.
        if (error.response && error.response.status === 401) {
          return;
        }

        // Otherwise call logout and redirect to login
        // auth.logout(() => {
        //   navigate('/login');
        // });
      }

      // // TODO:
      // setUser(data as any);
      setHasError(false);
    };

    callSendRequest();
  }, [sendRequest, isLoggedIn, navigate]);

  if (loading) {
    // TODO: loader
    return null;
  }

  if (hasError) {
    return null;
  }

  return children;
};

RequireAuth.displayName = 'RequireAuth';

export default RequireAuth;
