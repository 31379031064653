import { useRouteError, isRouteErrorResponse } from 'react-router-dom';
import { useEffect, useState } from 'react';
import ErrorDisplay from './ErrorDisplay';

export default function RootErrorBoundary() {
  const routeError = useRouteError() as Error;
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    if (isRouteErrorResponse(routeError)) {
      setErrorMessage(routeError.statusText);
    } else {
      setErrorMessage(routeError?.message ?? null);
    }
  }, [routeError]);

  return <ErrorDisplay errorMessage={errorMessage} />;
}
