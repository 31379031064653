import { Website } from '@/types/website';
import { LoadingIndicator } from '@/components/common/LoadingIndicator';
import { useTranslation } from '@/hooks/useTranslation';
import { Label } from '@/components/ui/label';
import { Input } from '@/components/ui/input';
import { Checkbox } from '@/components/ui/checkbox';
import { DialogFooter } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { format } from 'date-fns';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Calendar } from '@/components/ui/calendar';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { Calendar as CalendarIcon } from 'lucide-react';
import { cn } from '@/lib/utils';
import { Campaign } from '@/types/campaign';
import { InputPasteButton } from '@/components/InputPasteButton';

interface CampaignFormProps {
  data: Campaign;
  websites: Website[];
  onChange: (data: Campaign) => void;
  onClose: () => void;
  onSubmit: () => void;
  isLoading?: boolean;
}

export const CampaignForm = ({
  data,
  websites,
  onChange,
  onClose,
  onSubmit,
  isLoading,
}: CampaignFormProps) => {
  const { t } = useTranslation('common');
  const { name, website, startDate, endDate, isLooping } = data;

  return (
    <>
      <div>
        <Label htmlFor="website">{t('website')}</Label>
        {isLoading ? (
          <div className="w-full flex items-center justify-center">
            <LoadingIndicator />
          </div>
        ) : (
          <Select
            value={website.id}
            // TODO: remove disabled if campaign update can handle website change
            disabled
            onValueChange={(value: string) => {
              const selectedWebsite = websites?.find((w) => w.id === value);
              console.log('selectedWebsite', selectedWebsite);
              if (selectedWebsite) {
                onChange({ ...data, website: selectedWebsite });
              }
            }}
          >
            <SelectTrigger className="w-full">
              <SelectValue placeholder={t('pleaseSelectAWebsite')} />
            </SelectTrigger>
            <SelectContent>
              {websites?.map((website) => (
                <SelectItem key={website?.id} value={website?.id}>
                  {website?.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        )}
      </div>
      <div className="relative">
        <Label htmlFor="campaign-name">{t('campaignName')}</Label>
        <Input
          id="campaign-name"
          value={name}
          onChange={(e) => onChange({ ...data, name: e.target.value })}
        />
        <InputPasteButton
          onClick={async () => {
            const text = await navigator.clipboard.readText();
            onChange({ ...data, name: text });
          }}
        />
      </div>
      <div className="w-full grid grid-cols-1 lg:grid-cols-2 items-center">
        <Label htmlFor="start-date" className="col-span-1">
          {t('campaignStartDate')}
        </Label>
        <Popover>
          <PopoverTrigger asChild>
            <Button
              variant={'outline'}
              className={cn(
                'justify-start text-left font-normal col-span-1',
                !startDate && 'text-muted-foreground'
              )}
            >
              <CalendarIcon className="mr-2 h-4 w-4" />
              {startDate ? (
                format(startDate, 'PPP')
              ) : (
                <span>{t('pickAStartDate')}</span>
              )}
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-0">
            <Calendar
              id="start-date"
              mode="single"
              selected={new Date(startDate)}
              onSelect={(date?: Date) => {
                if (!date) return;
                onChange({
                  ...data,
                  startDate: format(date, 'yyyy-MM-dd'),
                });
              }}
              initialFocus
              fromDate={new Date()}
              toDate={new Date(endDate)}
            />
          </PopoverContent>
        </Popover>
      </div>
      <div className="w-full grid grid-cols-1 lg:grid-cols-2 items-center">
        <Label htmlFor="end-date" className="col-span-1">
          {t('campaignEndDate')}
        </Label>
        <Popover>
          <PopoverTrigger asChild>
            <Button
              variant={'outline'}
              className={cn(
                'justify-start text-left font-normal col-span-1',
                !startDate && 'text-muted-foreground'
              )}
            >
              <CalendarIcon className="mr-2 h-4 w-4" />
              {endDate ? (
                format(endDate, 'PPP')
              ) : (
                <span>{t('pickAnEndDate')}</span>
              )}
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-0">
            <Calendar
              id="end-date"
              mode="single"
              selected={new Date(endDate)}
              onSelect={(date?: Date) => {
                if (!date) return;
                onChange({
                  ...data,
                  endDate: format(date, 'yyyy-MM-dd'),
                });
              }}
              initialFocus
              fromDate={new Date(startDate)}
            />
          </PopoverContent>
        </Popover>
      </div>
      <div className="flex items-center gap-2">
        <Checkbox
          id="auto-restart"
          checked={isLooping}
          onCheckedChange={(checked: boolean) =>
            onChange({ ...data, isLooping: checked })
          }
        />
        <Label htmlFor="auto-restart">
          {t('theCampaignCanBeRestartedAutomatically')}
        </Label>
      </div>
      <DialogFooter className="mt-4">
        <Button variant="ghost" onClick={onClose}>
          {t('close')}
        </Button>
        <Button
          onClick={onSubmit}
          disabled={isLoading}
          className="flex gap-1"
          variant="conversionCraft"
        >
          {isLoading && <LoadingIndicator size="small" />}
          {t('save')}
        </Button>
      </DialogFooter>
    </>
  );
};
