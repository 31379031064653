import { Input } from '@/components/ui/input';
import { Checkbox } from '@/components/ui/checkbox';
import { DialogFooter } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { InputPasteButton } from '@/components/InputPasteButton';
import { Label } from '@/components/ui/label';
import { useTranslation } from '@/hooks/useTranslation';
import { Website } from '@/types/website';
import { LoadingIndicator } from '@/components/common/LoadingIndicator';

interface WebsiteFormProps {
  data: Website;
  onChange: (data: Website) => void;
  onClose: () => void;
  onSubmit: () => void;
  isLoading?: boolean;
}

export const WebsiteForm = ({
  data,
  onChange,
  onClose,
  onSubmit,
  isLoading,
}: WebsiteFormProps) => {
  const { t } = useTranslation('common');

  const {
    name,
    url,
    sitemapUrl,
    isUnas,
    unasApiKey,
    showPopupsOnAllPage,
    blockWebsite,
  } = data;

  return (
    <>
      <div className="relative">
        <Label htmlFor="website">{t('website')}</Label>
        <Input
          id="website"
          value={name}
          onChange={(e) => {
            onChange({ ...data, name: e.target.value });
          }}
          className="!bg-white"
        />
        <InputPasteButton
          onClick={async () => {
            const text = await navigator.clipboard.readText();
            onChange({ ...data, name: text });
          }}
        />
      </div>
      <div className="relative">
        <Label htmlFor="website-url">{t('websiteURL')}</Label>
        <Input
          id="website-url"
          value={url}
          onChange={(e) => {
            onChange({ ...data, url: e.target.value });
          }}
        />
        <InputPasteButton
          onClick={async () => {
            const text = await navigator.clipboard.readText();
            onChange({ ...data, url: text });
          }}
        />
      </div>
      <div className="relative">
        <Label htmlFor="sitemap-url">{t('sitemapURL')}</Label>
        <Input
          id="sitemap-url"
          value={sitemapUrl}
          onChange={(e) => {
            onChange({ ...data, sitemapUrl: e.target.value });
          }}
        />
        <InputPasteButton
          onClick={async () => {
            const text = await navigator.clipboard.readText();
            onChange({ ...data, sitemapUrl: text });
          }}
        />
      </div>
      <div className="flex items-center gap-2">
        <Checkbox
          id="unas-website"
          checked={isUnas}
          onCheckedChange={(checked: boolean) => {
            onChange({ ...data, isUnas: checked });
          }}
        />
        <Label htmlFor="unas-website">{t('unasSystemWebsite')}</Label>
      </div>
      <p className="text-gray-500 text-xs italic">
        {t('unasSystemWebsiteInfo')}
      </p>
      <div className="relative">
        <Label htmlFor="unas-api-key">{t('unasAPIKey')}</Label>
        <Input
          id="unas-api-key"
          value={unasApiKey}
          onChange={(e) => {
            onChange({ ...data, unasApiKey: e.target.value });
          }}
        />
        <InputPasteButton
          onClick={async () => {
            const text = await navigator.clipboard.readText();
            onChange({ ...data, unasApiKey: text });
          }}
        />
      </div>
      <p className="text-sm text-gray-500">{t('unasAPIKeyInfo')}</p>
      <div className="flex items-center gap-2">
        <Checkbox
          id="show-popups"
          checked={showPopupsOnAllPage}
          onCheckedChange={(checked: boolean) => {
            onChange({ ...data, showPopupsOnAllPage: checked });
          }}
        />
        <Label htmlFor="show-popups">{t('popupsCanAppearOnAllPages')}</Label>
      </div>
      <div className="flex items-center gap-2">
        <Checkbox
          id="disable-website"
          checked={blockWebsite}
          onCheckedChange={(checked: boolean) => {
            onChange({ ...data, blockWebsite: checked });
          }}
        />
        <Label htmlFor="disable-website">{t('blockWebsite')}</Label>
      </div>
      <DialogFooter className="mt-4">
        <Button variant="ghost" onClick={onClose}>
          {t('close')}
        </Button>
        <Button onClick={onSubmit} disabled={isLoading} className="flex gap-1">
          {isLoading && <LoadingIndicator size="small" />}
          {t('save')}
        </Button>
      </DialogFooter>
    </>
  );
};
