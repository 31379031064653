import { useTranslation } from '@/hooks/useTranslation';
import { Modal } from './Modal';
import { useEffect, useState } from 'react';
import { useModalContext } from '@/hooks/useModalContext';
import {
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '../ui/carousel';
import { Card, CardContent } from '../ui/card';
import { Button } from '../ui/button';
import { useUserStore } from '@/stores/user';

export const MainOnboardingModal = () => {
  const { t } = useTranslation('common');
  const { closeModal } = useModalContext();
  const wasMainOnboardingShown = useUserStore(
    (state) => state.setWasMainOnboardingShown
  );

  const [api, setApi] = useState<CarouselApi>();
  const [current, setCurrent] = useState(0);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (!api) {
      return;
    }

    setCount(api.scrollSnapList().length);
    setCurrent(api.selectedScrollSnap() + 1);

    api.on('select', () => {
      setCurrent(api.selectedScrollSnap() + 1);
    });
  }, [api]);

  return (
    <Modal modalType="mainOnboarding" title={t('welcomeOnBoard')}>
      <div className="w-full h-full flex flex-col items-conter justify-center">
        <div className="h-[28rem] w-full flex items-center justify-center">
          <Carousel setApi={setApi} className="w-full max-w-xs md:max-w-md">
            <CarouselContent>
              {Array.from({ length: 5 }).map((_, index) => (
                <CarouselItem key={index}>
                  <Card className="border-none shadow-none">
                    <CardContent className="flex aspect-square items-center justify-center">
                      <span className="text-4xl font-semibold">
                        {index + 1}
                      </span>
                    </CardContent>
                  </Card>
                </CarouselItem>
              ))}
            </CarouselContent>
            <CarouselPrevious />
            <CarouselNext />
          </Carousel>
        </div>
        {current !== count ? (
          <div className="h-8 text-center text-sm text-muted-foreground">
            {t('xStepOfOnboarding', { step: current, total: count })}
          </div>
        ) : (
          <div className="h-8 flex justify-center gap-4">
            <Button
              onClick={() => {
                closeModal('mainOnboarding');
                wasMainOnboardingShown();
              }}
              className="btn btn-primary"
              type="button"
            >
              {t('letsGetStarted')}
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
};
