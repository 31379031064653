import React from 'react';
import { AuthContext, AuthContextParams } from '../hooks/useAuth';
import { useUserStore } from '../stores/user';
import { useClientStore } from '@/stores/client';
import { UserType } from '@/types/user';

const emptyUser: UserType = {
  id: '',
  name: '',
  emailVerifiedAt: null,
  email: '',
  updated: null,
  created: '',
};

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const {
    login: stateLogin,
    logout: stateLogout,
    user,
    isLoggedIn,
    token,
  } = useUserStore();

  const selectedClient = useClientStore((state) => state.client);

  const value: AuthContextParams = {
    user: user || emptyUser,
    token: token || '',
    setToken: (token: string) => {
      localStorage.setItem('token', token);
    },
    client: selectedClient?._id ?? null,
    isLoggedIn,
    login: (
      newUser: UserType,
      authResponse: {
        access_token: string;
        token_type: string;
        expires_in: string;
      },
      callback: VoidFunction
    ) => {
      const { access_token } = authResponse;
      localStorage.setItem('token', access_token);
      stateLogin(newUser, access_token);
      callback();
    },
    logout: (callback?: VoidFunction) => {
      stateLogout();
      if (callback) {
        callback();
      }
    },
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

AuthProvider.displayName = 'AuthProvider';

export default AuthProvider;
