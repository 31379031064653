import { CreateWebsiteModal } from '../modals/CreateWebsiteModal';
import { CreateCampaignModal } from '../modals/CreateCampaignModal';
import { MainOnboardingModal } from '../modals/MainOnboardingModal';
import { WebsiteOnboardingAndHelpModal } from '../modals/WebsiteOnboardingAndHelpModal';
import { CampaignOnboardingAndHelpModal } from '../modals/CampaignOnboardingAndHelpModal';
import { PopupOnboardingAndHelpModal } from '../modals/PopupOnboardingAndHelpModal';
import { MainWalkthrough } from '../layout/MainWalkthrough';
import { CreateBasicWebsiteModal } from '../modals/CreateBasicWebsiteModal';
import { UpdateWebsiteModal } from '../modals/UpdateWebsiteModal';
import { useUserStore } from '@/stores/user';
import { UpdateCampaignModal } from '../modals/UpdateCampaignModal';

export function ModelsContainer() {
  const wasMainOnboardingShown = useUserStore(
    (state) => state.wasMainOnboardingShown
  );

  const wasMainWalkthroughShown = useUserStore(
    (state) => state.wasMainWalkthroughShown
  );

  return (
    <>
      <CreateWebsiteModal />
      <CreateBasicWebsiteModal />
      <UpdateWebsiteModal />
      <CreateCampaignModal />
      <UpdateCampaignModal />
      {!wasMainOnboardingShown && <MainOnboardingModal />}
      <WebsiteOnboardingAndHelpModal />
      <CampaignOnboardingAndHelpModal />
      <PopupOnboardingAndHelpModal />
      {wasMainOnboardingShown && !wasMainWalkthroughShown && (
        <MainWalkthrough />
      )}
    </>
  );
}
