import { Bell, BellOff } from 'lucide-react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from './ui/dropdown-menu';
import { Button } from './ui/button';
import { useTranslation } from '@/hooks/useTranslation';
import { useEffect, useState } from 'react';
import { LoadingIndicator } from './common/LoadingIndicator';

export const Notifications = () => {
  const { t } = useTranslation('common');
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!open) return;

    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [open]);

  return (
    <DropdownMenu
      onOpenChange={(isOpen) => {
        setOpen(isOpen);
      }}
    >
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" size="icon">
          <Bell className="h-5 w-5" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <div className="relative w-[16rem] h-[24rem] flex flex-col items-center justify-center gap-4">
          <div className="absolute top-0 left-0 p-2 w-full border-b text-sm font-semibold">
            {t('notifications')}
          </div>
          {loading ? (
            <LoadingIndicator size="large" className="text-muted-foreground" />
          ) : (
            <>
              <span className="text-sm text-muted-foreground">
                {t('noNotificationsCurrently')}
              </span>
              <BellOff className="w-8 h-8 text-muted-foreground" />
            </>
          )}
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
