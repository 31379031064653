import { FC, ReactNode } from 'react';
import { Tooltip, TooltipContent, TooltipTrigger } from './ui/tooltip';

interface TooltipWrapperProps {
  children: ReactNode;
  label: string;
  side?: 'top' | 'right' | 'bottom' | 'left';
}

export const TooltipWrapper: FC<TooltipWrapperProps> = ({
  children,
  label,
  side = 'right',
}) => {
  return (
    <Tooltip>
      <TooltipTrigger asChild>{children}</TooltipTrigger>
      <TooltipContent side={side}>{label}</TooltipContent>
    </Tooltip>
  );
};
