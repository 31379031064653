import { ReactNode } from 'react';
import { Button } from '../ui/button';
import { useTranslation } from '@/hooks/useTranslation';

interface HelpModalBaseProps {
  children: ReactNode;
  onSubmit?: () => void;
  submitButtonLabel?: string;
  onClose?: () => void;
  closeButtonLabel?: string;
}

export const HelpModalBase = ({
  children,
  submitButtonLabel,
  onSubmit,
  closeButtonLabel,
  onClose,
}: HelpModalBaseProps) => {
  const { t } = useTranslation('common');

  return (
    <div className="w-full h-full flex flex-col items-conter justify-center">
      <div className="h-[28rem] w-full flex flex-col items-center justify-center">
        <div className="h-full w-full">{children}</div>
        <div className="flex w-full items-center justify-end">
          <div className="flex gap-2 items-center">
            {onClose && (
              <Button size="sm" onClick={onClose}>
                <span>{closeButtonLabel || t('close')}</span>
              </Button>
            )}
            {onSubmit && (
              <Button size="sm" onClick={onSubmit}>
                <span>{submitButtonLabel || t('submit')}</span>
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
