import {
  InvalidateQueryFilters,
  QueryFilters,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { SendRequestResponse, useApi } from '@/hooks/useApi';
import { CAMPAIGNS } from '@/queryKeys';
import { endpoints } from '@/endpoints';
import { CampaignBE } from '@/types/campaign';
import { mapCampaignDTOToBEForCreate } from '@/mappers/mapCampaignDTO';

const CAMPAIGNS_QUERY_KEY = [CAMPAIGNS];

export const useCreateCampaign = <Campaign>({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: () => void;
}) => {
  const queryClient = useQueryClient();

  const { sendRequest } = useApi<CampaignBE>({
    url: endpoints.campaigns(),
    method: 'post',
  });

  return useMutation<
    SendRequestResponse<CampaignBE>,
    Error,
    Partial<Campaign>,
    { previousCampaigns: CampaignBE[] | undefined }
  >({
    mutationFn: async (data: Partial<Campaign>) => {
      const mappedData = mapCampaignDTOToBEForCreate(data);
      const response = await sendRequest(mappedData);

      if (response?.error) {
        throw new Error(response.error.message);
      }

      return response;
    },
    // With onMutate, we can update the cache before the mutation is executed
    onMutate: async (data: Partial<CampaignBE>) => {
      await queryClient.cancelQueries(CAMPAIGNS_QUERY_KEY as QueryFilters);

      const previousCampaigns = queryClient.getQueryData<CampaignBE[]>([
        CAMPAIGNS,
      ]);

      queryClient.setQueryData<Partial<CampaignBE>[]>([CAMPAIGNS], (old) => {
        if (old) {
          return [...old, data];
        }
        return [data];
      });

      return { previousCampaigns };
    },
    onError: (_, __, context) => {
      console.log('ERROR');
      if (context?.previousCampaigns) {
        queryClient.setQueryData([CAMPAIGNS], context.previousCampaigns);
      }
      onError?.();
    },
    onSuccess: () => {
      queryClient.invalidateQueries(
        CAMPAIGNS_QUERY_KEY as InvalidateQueryFilters
      );
      onSuccess?.();
    },
  });
};
