import { create } from 'zustand';
import { devtools, persist, createJSONStorage } from 'zustand/middleware';
import { produce } from 'immer';
import { Menu } from '@/types/menus';

type ClientMenu = {
  title: string;
  icon: string;
  createEnabled: string;
};

export type Client = {
  _id: string;
  name: string;
  menus: ClientMenu[];
  modules: string[];
  created: string;
  updated: string;
  role?: string;
};

export type ClientStore = {
  client: Client | null;
  availableClients: Client[];
  menu: Menu | null;
  setClient: (client: Client) => void;
  setMenu: (menu: Menu) => void;
  setAvailableClients: (clients: Client[]) => void;
};

export const useClientStore = create<ClientStore>()(
  devtools(
    persist(
      (set) => ({
        client: null,
        menu: null,
        availableClients: [],
        setClient: (client: Client) => {
          set(
            produce((state: ClientStore): void => {
              localStorage.setItem('selected-client', client._id);
              state.client = client;
            })
          );
        },
        setMenu: (menu: Menu) => {
          set(
            produce((state: ClientStore): void => {
              state.menu = menu;
            })
          );
        },
        setAvailableClients: (clients: Client[]) => {
          set(
            produce((state: ClientStore): void => {
              state.availableClients = clients;
              if (!state.client) {
                state.client = clients[0];
              }
            })
          );
        },
      }),
      {
        name: 'client',
        storage: createJSONStorage(() => localStorage),
      }
    )
  )
);
