import { useTranslation } from '@/hooks/useTranslation';
import { Modal } from './Modal';
import { useState } from 'react';
import { Label } from '../ui/label';
import { Input } from '../ui/input';
import { Checkbox } from '../ui/checkbox';
import { DialogFooter } from '../ui/dialog';
import { Button } from '../ui/button';
import { useModalContext } from '@/hooks/useModalContext';
import { InputPasteButton } from '../InputPasteButton';
import { format } from 'date-fns';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select';
import { Calendar } from '../ui/calendar';
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover';
import { Calendar as CalendarIcon } from 'lucide-react';
import {
  cn,
  convertDateToBackendFormat,
  handleCheckboxChange,
} from '@/lib/utils';
import { useCreateCampaign } from '@/hooks/campaign/useCreateCampaign';
import { toast } from 'sonner';
import { useGetWebsiteList } from '@/hooks/website/useGetWebsiteList';
import { LoadingIndicator } from '../common/LoadingIndicator';

export const CreateCampaignModal = () => {
  const { t } = useTranslation('common');
  const { closeModal, isModalOpen } = useModalContext();

  const handleClose = () => {
    closeModal('createNewWebsite');
  };

  const [website, setWebsite] = useState('');
  const [name, setName] = useState('');
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [isLooping, setIsLooping] = useState(false);

  const { data: websites, isLoading: websitesLoading } = useGetWebsiteList({
    enabled: isModalOpen,
  });

  const { mutate: createCampaign, isPending: isCreatingCampaign } =
    useCreateCampaign({
      onSuccess: () => {
        toast.success(t('campaignCreatedSuccessfully'));
        handleClose();
      },
      onError: () => {
        toast.error(t('failedToCreateCampaign'));
      },
    });

  const handleSave = () => {
    console.log('website', website);

    createCampaign({
      website: { id: website },
      name,
      // TODO: Fix date format on BE
      startDate: convertDateToBackendFormat(startDate),
      endDate: convertDateToBackendFormat(endDate),
      isLooping,
    });
  };

  const handlePaste = async (
    setValue: React.Dispatch<React.SetStateAction<string>>
  ) => {
    try {
      const text = await navigator.clipboard.readText();
      setValue(text);
    } catch (err) {
      console.error('Failed to read clipboard contents: ', err);
    }
  };

  return (
    <Modal modalType="createNewCampaign" title={t('createNewCampaign')}>
      <div className="space-y-4">
        <div>
          <Label htmlFor="website">{t('website')}</Label>
          {websitesLoading ? (
            <div className="w-full flex items-center justify-center">
              <LoadingIndicator />
            </div>
          ) : (
            <Select value={website} onValueChange={(v) => setWebsite(v)}>
              <SelectTrigger className="w-full">
                <SelectValue placeholder={t('pleaseSelectAWebsite')} />
              </SelectTrigger>
              <SelectContent>
                {websites?.map((website) => (
                  <SelectItem key={website.id} value={website.id}>
                    {website.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          )}
        </div>
        <div className="relative">
          <Label htmlFor="campaign-name">{t('campaignName')}</Label>
          <Input
            id="campaign-name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <InputPasteButton onClick={() => handlePaste(setName)} />
        </div>
        <div className="w-full grid grid-cols-1 lg:grid-cols-2 items-center">
          <Label htmlFor="start-date" className="col-span-1">
            {t('campaignStartDate')}
          </Label>
          <Popover>
            <PopoverTrigger asChild>
              <Button
                variant={'outline'}
                className={cn(
                  'justify-start text-left font-normal col-span-1',
                  !startDate && 'text-muted-foreground'
                )}
              >
                <CalendarIcon className="mr-2 h-4 w-4" />
                {startDate ? (
                  format(startDate, 'PPP')
                ) : (
                  <span>{t('pickAStartDate')}</span>
                )}
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0">
              <Calendar
                id="start-date"
                mode="single"
                selected={startDate}
                onSelect={setStartDate}
                initialFocus
                fromDate={new Date()}
                toDate={endDate}
              />
            </PopoverContent>
          </Popover>
        </div>
        <div className="w-full grid grid-cols-1 lg:grid-cols-2 items-center">
          <Label htmlFor="end-date" className="col-span-1">
            {t('campaignEndDate')}
          </Label>
          <Popover>
            <PopoverTrigger asChild>
              <Button
                variant={'outline'}
                className={cn(
                  'justify-start text-left font-normal col-span-1',
                  !startDate && 'text-muted-foreground'
                )}
              >
                <CalendarIcon className="mr-2 h-4 w-4" />
                {endDate ? (
                  format(endDate, 'PPP')
                ) : (
                  <span>{t('pickAnEndDate')}</span>
                )}
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0">
              <Calendar
                id="end-date"
                mode="single"
                selected={endDate}
                onSelect={setEndDate}
                initialFocus
                fromDate={startDate}
              />
            </PopoverContent>
          </Popover>
        </div>
        <div className="flex items-center gap-2">
          <Checkbox
            id="auto-restart"
            checked={isLooping}
            onCheckedChange={handleCheckboxChange(setIsLooping)}
          />
          <Label htmlFor="auto-restart">
            {t('theCampaignCanBeRestartedAutomatically')}
          </Label>
        </div>
        <DialogFooter className="mt-4">
          <Button variant="ghost" onClick={handleClose}>
            {t('close')}
          </Button>
          <Button
            onClick={handleSave}
            disabled={isCreatingCampaign}
            className="flex gap-1"
            variant="conversionCraft"
          >
            {isCreatingCampaign && <LoadingIndicator size="small" />}
            {t('save')}
          </Button>
        </DialogFooter>
      </div>
    </Modal>
  );
};
