import {
  InvalidateQueryFilters,
  QueryFilters,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { SendRequestResponse, useApi } from '@/hooks/useApi';
import { WEBSITES } from '@/queryKeys';
import { endpoints } from '@/endpoints';
import { WebsiteBE } from '@/types/website';
import { mapWebsiteDTOToBEForUpdate } from '@/mappers/mapWebsiteDTO';

const WEBSITES_QUERY_KEY = [WEBSITES];

export const useUpdateWebsite = <Website>({
  id,
  onSuccess,
  onError,
}: {
  id: string;
  onSuccess?: () => void;
  onError?: () => void;
}) => {
  const queryClient = useQueryClient();

  const { sendRequest } = useApi<WebsiteBE>({
    url: endpoints.website(id),
    method: 'put',
  });

  return useMutation<
    SendRequestResponse<WebsiteBE>,
    Error,
    { id: number; data: Partial<Website> },
    { previousWebsites: WebsiteBE[] | undefined }
  >({
    mutationFn: async ({ id, data }) => {
      const mappedData = mapWebsiteDTOToBEForUpdate(data);
      const response = await sendRequest({ id, ...mappedData });

      if (response?.error) {
        throw new Error(response.error.message);
      }

      return response;
    },
    onMutate: async ({ id, data }) => {
      await queryClient.cancelQueries(WEBSITES_QUERY_KEY as QueryFilters);

      const previousWebsites = queryClient.getQueryData<WebsiteBE[]>([
        WEBSITES,
      ]);

      queryClient.setQueryData<WebsiteBE[]>([WEBSITES], (old) => {
        if (old) {
          return old.map((website) =>
            website.id === id ? { ...website, ...data } : website
          );
        }
        return old;
      });

      return { previousWebsites };
    },
    onError: (_, __, context) => {
      if (context?.previousWebsites) {
        queryClient.setQueryData([WEBSITES], context.previousWebsites);
      }
      onError?.();
    },
    onSuccess: () => {
      queryClient.invalidateQueries(
        WEBSITES_QUERY_KEY as InvalidateQueryFilters
      );
      onSuccess?.();
    },
  });
};
