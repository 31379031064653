import commonEN from './en/common.json';
import commonHU from './hu/common.json';

export const resources = {
  'en-US': {
    common: commonEN,
  },
  'hu-HU': {
    common: commonHU,
  },
};
