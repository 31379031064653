import { produce } from 'immer';
import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

export type ModalType =
  | 'createNewWebsite'
  | 'createNewBasicWebsite'
  | 'updateWebsite'
  | 'createNewCampaign'
  | 'updateCampaign'
  | 'mainOnboarding'
  | 'websiteOnboardingAndHelp'
  | 'campaignOnboardingAndHelp'
  | 'popupOnboardingAndHelp';

export interface ModalStore {
  modals: Record<ModalType, boolean>;
  setModal: (modal: ModalType, value: boolean) => void;
}

const defaultStore: Pick<ModalStore, 'modals'> = {
  modals: {
    createNewWebsite: false,
    createNewBasicWebsite: false,
    updateWebsite: false,
    createNewCampaign: false,
    updateCampaign: false,
    mainOnboarding: false,
    websiteOnboardingAndHelp: false,
    campaignOnboardingAndHelp: false,
    popupOnboardingAndHelp: false,
  },
};

export const useModalStore = create<ModalStore>()(
  devtools(
    persist(
      (set) => ({
        ...defaultStore,
        setModal: (modal: ModalType, value: boolean) => {
          set(
            produce((state: ModalStore) => {
              state.modals[modal] = value;
            })
          );
        },
      }),
      {
        name: 'modals',
        storage: createJSONStorage(() => sessionStorage),
      }
    )
  )
);
